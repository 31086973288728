<h2 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <ng-container *ngIf="!showVerifyEmail; else dialogNameTemplate">Update Email</ng-container>
    <ng-template #dialogNameTemplate>Verify Email</ng-template>
</h2>
<mat-dialog-content class="mat-typography">
    <busy-overlay [show]="isBusy"></busy-overlay>

    <ng-container *ngIf="isSignedIn; else noLoggedIn">
        <ng-container *ngIf="!showVerifyEmail; else verifyTemplate">
            <div [formGroup]="changeGroup" style="overflow: auto; display:flex; flex-direction:column;">

                <p class="mat-body-2">
                    To update your email address from <b>{{currentEmail}}</b> enter a new one below. You will then
                    receive an
                    email with a code you need to enter to verify you own the email.
                </p>

                <mat-form-field>
                    <mat-label>New Email</mat-label>
                    <input matInput type="search" autocomplete="off" formControlName="NewEmail"
                           (keyup.enter)="onChangeEmail()" autofocus />
                    <mat-error *ngIf="newEmail.hasError('required')">required</mat-error>
                    <mat-error *ngIf="newEmail.hasError('pattern')">format not valid</mat-error>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Confirm Email</mat-label>
                    <input matInput type="search" autocomplete="off" blockPaste
                           formControlName="ConfirmEmail" (keyup.enter)="onChangeEmail()" />
                    <mat-error *ngIf="confirmEmail.hasError('required')">required</mat-error>
                    <!--we don't care about the pattern on the confirming email, because the other one has it-->
                    <mat-error *ngIf="confirmEmail.hasError('equalValue')">emails must match</mat-error>
                </mat-form-field>
            </div>
        </ng-container>

            <ng-template #verifyTemplate>
                <div [formGroup]="verifyGroup" style="overflow: auto; display: flex; flex-direction: column;">

                    <p class="mat-body-2">
                        Your email address isn't verified.  If you don't verify it then you will not be able to reset your password.
                        Check the email address shown below for a verification code.
                        If you do not have a verification code, click the "Send Code" option below.
                    </p>
                    <p class="mat-body-2">
                        If the email shown below is not your email address, click the "Change Email" option to update it.
                    </p>

                    <mat-form-field>
                        <mat-label>Email</mat-label>
                        <input matInput type="search" autocomplete="off" formControlName="Email" />
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Code</mat-label>
                        <input matInput type="search" autocomplete="off" formControlName="Code"
                               (keyup.enter)="onVerify()" autofocus />
                        <mat-error *ngIf="code.hasError('required')">required</mat-error>
                    </mat-form-field>

                </div>
            </ng-template>
        </ng-container>
    <ng-template #noLoggedIn>
        <label class="mat-caption mat-error">No logged in user, this should not be shown</label>
    </ng-template>
</mat-dialog-content>

<mat-dialog-actions style="align-content: flex-end; display: flex; flex-direction: column;">
    <span style="text-align: right; width: 100%;">
        <ng-container *ngIf="!showVerifyEmail; else verifyActionsTemplate">
            <a class="link mat-caption" (click)="showVerifyEmail = true" tabindex="0">Already have a Code</a>
        </ng-container>
        <ng-template #verifyActionsTemplate>
            <div style="display: flex; place-content: center space-between;">
                <a class="link mat-caption" (click)="showVerifyEmail = false" tabindex="0">Change Email</a>
                <a class="link mat-caption" (click)="ResendCode()" tabindex="0">Send Code</a>
            </div>
        </ng-template>
    </span>

    <label class="mat-caption mat-error" style="text-align: right; width: 100%;" *ngIf="_errorMessage">{{_errorMessage}}</label>

    <div style="padding-top: 15px; width: 100%; display: flex; place-content: center flex-end;">
        <iq-icon-button style="padding-right:2em" button-class="Dialog" icon="Cancel" [mat-dialog-close]="null">Cancel</iq-icon-button>

        <ng-container *ngIf="!showVerifyEmail; else verifyButtonsTemplate">
            <iq-icon-button button-class="Dialog" icon="Save" [disabled]="!changeGroup.valid || isBusy" (click)="onChangeEmail()">Set Email</iq-icon-button>
        </ng-container>
        <ng-template #verifyButtonsTemplate>
            <iq-icon-button button-class="Dialog" icon="Save" [disabled]="!verifyGroup.valid || isBusy" (click)="onVerify()">Confirm Email</iq-icon-button>
        </ng-template>
    </div>
</mat-dialog-actions>
