import { Component, Input, OnInit } from '@angular/core';
import { TicketEntryAllowedTicketActions } from 'Models/Tickets/TicketEntryAllowedTicketActions';
import { TicketActionsService } from 'Pages/Tickets/Services/TicketActions.service';
import { TicketService } from 'Pages/Tickets/Services/TicketService';

@Component({
    selector: 'ticket-work-complete',
    templateUrl: './TicketWorkComplete.component.html',
    styleUrls: ['./TicketWorkComplete.component.scss']
})
export class TicketWorkCompleteComponent implements OnInit {

    //  *** Only need to set these 2 input's if this is used outside of the ticket entry page.
    //  (i.e. on the View Responses dialog from the ticket list)
    //  Otherwise, we get them using the TicketService so there is no need.
    //  We will also update the TicketService.TicketConfiguration.AllowedActions so that the rest of the
    //  page will re-configure itself if a ticket is marked [not] complete.

    //  Ticket or a ticket list item
    @Input()
    public Ticket: any;

    private _AllowedActions;
    @Input()
    public get AllowedActions(): TicketEntryAllowedTicketActions {
        //  If we were given 
        if (this._AllowedActions)
            return this._AllowedActions;
        if (!this._TicketService.EditingTicket.value)
            return this._TicketService.TicketConfiguration?.AllowedActions;
        return null;
    }
    public set AllowedActions(allowedActions: TicketEntryAllowedTicketActions) {
        this._AllowedActions = allowedActions;
    }

    constructor(private _TicketActionsService: TicketActionsService, private _TicketService: TicketService)
    {
    }

    public ngOnInit(): void {
        if (!this.Ticket && !this._TicketService.EditingTicket.value) {
            //  Ticket not set and we are not editing, we are on the ticket entry page so get from the TicketService
            this.Ticket = this._TicketService.Ticket.value;
        }
    }

    public ToggleWorkCompleted(): void {

        //  Ticket is either a Ticket or a ticket list item.  And if it's a list item, it's a string of Yes or No (because can be displayed as a column).
        const isComplete = (this.Ticket.IsWorkComplete === "Yes") || (this.Ticket.IsWorkComplete === true);

        this._TicketActionsService.MarkWorkCompleted([this.Ticket.ID], !isComplete)
            .subscribe((allowedActions) => {
                if (!allowedActions)
                    return;     //  User canceled dialog or did not have permission to update anything (which should not happen)

                //  If we were given AllowedActions, update with what we just got.  Otherwise, do *NOT* update
                //  so that we always use what is currently stored in TicketService.TicketConfiguration.AllowedActions.
                //  TicketService.TicketConfiguration.AllowedActions is set internally by _TicketActionsService.MarkWorkCompleted().
                //  This will cause the rest of the page (especially) the header) to update itself if necessary.  i.e. we mark the
                //  ticket as Work Complete - now ticket functions are not allowed so those buttons need to be removed from the page.
                //  And we do not update our internal _AllowedActions unless we were given one becuase otherwise we will have
                //  issues on Phone when on the ticket details page - there are 2 ways to toggle it: either from this component
                //  or via the Actions menu.  If you do it from the Actions menu, this component won't know about it!
                if (this._AllowedActions)
                    this._AllowedActions = allowedActions;

                //  IsWorkComplete has been toggled.  Update it here in case it's visible (and in case the
                //  user re-opens the menu) and invalidate the menu so it's re-built.
                if (typeof this.Ticket.IsWorkComplete === 'string')
                    this.Ticket.IsWorkComplete = isComplete ? "No" : "Yes";
                else
                    this.Ticket.IsWorkComplete = !isComplete;
            });
    }
}
