import { AfterContentInit, Directive, ElementRef, OnDestroy } from "@angular/core";
import { NgControl } from "@angular/forms";
import { IQ_MASK_FORMATS } from "@iqSharedUtils/MaskFormats.model";
import { FieldUIControlTypeEnum } from "Enums/FieldUIControlType.enum";
import * as _ from "lodash";
import { EntryFieldConfigurationResponse } from "Models/EntryFields/EntryFieldConfigurationResponse.model";
import { Subject } from "rxjs";
import { pairwise, startWith, takeUntil } from "rxjs/operators";
import { TextareaInputLimiterDirective } from "Shared/Directives/TextAreaInputLimiter.directive";
import { EntryFormControl } from 'Shared/EntryFields/Forms/EntryFormControl';
import { conformToMask } from "text-mask-core";
import { EntryFormGroupBase } from "../Forms/EntryFormGroupBase";

/**
 * Put this directive on a ticket entry input/select control.  It will validate the ticket entry field configuration
 * as defined in the EntryFormControl that is attached to the input/select control (via the formControlName).
 * It will also trigger keyboard shortcuts to do searching and such.
 * https://stackoverflow.com/questions/41171686/angular2-v-2-3-have-a-directive-access-a-formcontrol-created-through-formcontr
 */
@Directive({
    selector: "[iq-entry-field]",
    host: {
        '(input)': 'OnInputEvent($event)',                              //  Change of input (insert or delete character)
        '(focus)': 'OnFocus($event.target)',
        '(blur)': 'OnBlur()',
        '(keydown)': 'OnKeydownEvent($event)',                          //  For detecting shortcut keys
        //'(keydown.backspace)': 'onKeydownBackspaceEvent($event)',       //  Can trigger a keydown event on a specific key like this (name is lowercase $event.key)
        //'(keydown.f2)': 'onKeydownF2Event($event)',
    }
})
export class EntryFieldDirective implements AfterContentInit, OnDestroy {

    private _FormControl: EntryFormControl;
    private _FieldConfig: EntryFieldConfigurationResponse;
    private _Destroyed: Subject<void> = new Subject();

    get FormControl(): EntryFormControl {
        return this._FormControl;
    }

    public InputHint: string = null;

    constructor(private _Element: ElementRef<HTMLElement>, private _Control: NgControl)
    { }

    ngOnDestroy() {
        this._Destroyed.next();
        this._Destroyed.complete();

        this._Element = null;
        this._Control = null;
        this._FormControl = null;
        this._FieldConfig = null;
    }

    ngAfterContentInit(): void {
        //  These fields are not available until the content has been initialized
        this._FormControl = this._Control.control as EntryFormControl;
        if (!this._FormControl) {
            console.error("** ERROR: iq-entry-field directive used on a control that does not have a EntryFormControl defined", this._Control);
            return;
        }

        this._FieldConfig = this._FormControl.FieldConfiguration;
        if (!this._FieldConfig) {
            //  If this happens, someone forgot to set this when creating the EntryFormControl or the server is not
            //  returning the field config for some reason!
            console.error("** ERROR: TicketFieldConfiguration not set for property", this._Control);
            return;
        }

        if (!this._FormControl.FieldIsEnabled()) {
            //  Must change the disabled flag AFTER this digest cycle has completed or we will get a
            //  "Expression has changed after it was checked" error.
            //  See: https://blog.angularindepth.com/everything-you-need-to-know-about-the-expressionchangedafterithasbeencheckederror-error-e3fd9ce7dbb4
            void Promise.resolve(null).then(() => this._FormControl.disable({ onlySelf: true, emitEvent: false }));
        }

        const targetElement = this._Element.nativeElement as HTMLInputElement;
        if (this._FieldConfig.UIControlType === FieldUIControlTypeEnum.Phone) {
            //  Need to manually apply the mask.  Only set the HTML element!  We only store the unmasked value in the FormControl
            //  (which is done when the control loses focus).  So when showing the element, we need to manually set the masked value.
            setTimeout(() => targetElement.value = this.MaskPhoneValue(this._FormControl?.value));      //  "?" b/c got a js error once after saving a void - but could not reproduce again
        }
        else if (this._FieldConfig.UIControlType === FieldUIControlTypeEnum.TextMask) {
            //  Handled like Phone above.  But the TextMask should be defined in FormControl.TicketFieldConfiguration.TextMask
            setTimeout(() => targetElement.value = this.MaskTextMaskValue(this._FormControl?.value));
        }

        //  Need to subscribe for changes in case the field is a phone field (or if it's LATER CHANGED to a phone field)
        //  so that we can display the masked value.
        //  And if the form control value changes, need to mask the new value (i.e. user picks from an autocomplete)
        if ((this._FieldConfig.UIControlType === FieldUIControlTypeEnum.Phone) || (this._FieldConfig.UIControlType === FieldUIControlTypeEnum.Email)) {
            //  The only fields that can switch to Phone are the alt contact fields that can either be phone or email.  So only need the event
            //  handler if it's 1 of those 2 types.  Saves a bunch of input subscriptions by checking that...
            this._FormControl.valueChanges
                .pipe(takeUntil(this._Destroyed))
                .subscribe(value => {
                    if (this._FieldConfig.UIControlType === FieldUIControlTypeEnum.Phone)
                        setTimeout(() => targetElement.value = this.MaskPhoneValue(value));
                });
        }

        //  See notes in OnKeydownEvent
        if (this._Element.nativeElement.localName === "mat-select") {
            this._PreviousValue = this._FormControl.value;
            this._LastChangeTimestamp = Date.now();
            this._FormControl.valueChanges
                .pipe(
                    takeUntil(this._Destroyed),
                    startWith(this._PreviousValue),
                    pairwise()
            ).subscribe(([prevValue, selectedValue]) => {
                this._PreviousValue = prevValue;
                this._LastChangeTimestamp = Date.now();
            });
        }
    }

    private _PreviousValue: any;
    private _LastChangeTimestamp: number;

    private OnKeydownEvent(event: KeyboardEvent) {
        if (!this._FormControl || !this._FieldConfig)
            return;     //  not configured correctly or no shortcut keys for this field
        if ((this._Element.nativeElement.localName === "ng-select") || (this._Element.nativeElement.localName === "mtx-select"))
            return;     //  Ignore all keyboard events on an <ng-select> and MtxSelect (which is a Material form field wrapper to ng-select)

        if ((this._Element.nativeElement.localName === "mat-select") && ((event.code === "PageDown") || (event.code === "PageUp"))) {
            //  This is a hack to work around a change made in Angular 15.  It now automatically handles PageUp/PageDown inside the MatSelect.
            //  It results in setting the selected item to either the first/last in the list (or maybe paged by 10 items?).
            //  This hack will restore the value to the previous value on PageUp/PageDown so that it is not changed.
            //  And because the PageUp/PagaeDown trigger a change, we only change the value if we captured a change event within the last 10ms.
            //  Otherwise, we do not change the value.  This fixes issues where the first or last value is already selected and then you PageUp/PageDown
            //  (which does not trigger a change since the first/last item is already selected).
            //  Links to the changes: https://github.com/angular/components/issues/3549, https://github.com/angular/components/pull/25508
            if ((this._PreviousValue !== this._FormControl.value) && ((Date.now() - this._LastChangeTimestamp) < 10))
                this._FormControl.setValue(this._PreviousValue);
            return;
        }

        //  *** This check must always be done - it's needed by FL's shortcut keys (used to create emergency & design tickets).
        //  And it's done in their work start date field which is not a normal input field.
        if (this._FieldConfig.ShortcutKeys && (this._FieldConfig.ShortcutKeys.length > 0)) {
            //  Check to see if key is one of the configured shortcut keys
            const key = event.key.toLowerCase();
            if (_.includes(this._FieldConfig.ShortcutKeys, key)) {
                //  Key matches a shortcut that is configured for this property
                const rootFormGroup = this.FormControl.root;
                if (rootFormGroup instanceof EntryFormGroupBase) {
                    //  Dispatch the shortcut key to the EntryFormGroupBase
                    if (rootFormGroup.OnShortcutKey(this._FieldConfig.PropertyName, key, this.FormControl)) {
                        //  Key has been handled so ignore the event so it's not processed into the input control
                        event.stopPropagation();
                        event.preventDefault();
                        return;
                    }
                }
            }
        }

        const targetElem = event.target as HTMLInputElement;
        let caretPos = -1;
        if (targetElem.type !== "checkbox") {
            //  Safari on Mac will throw a "type error" if we access selectionStart for a checkbox!
            caretPos = targetElem.selectionStart;
        }

        switch (this._FieldConfig.UIControlType) {
            case FieldUIControlTypeEnum.MultipleSelectAutocomplete:
            case FieldUIControlTypeEnum.TextMask:
                //  Don't handle input at all for these types of controls.
                //  For MultipleSelectAutocomplete: The input for this field is captured into
                //      a separate <input> within TicketEntryMultipleSelectAutocompleteComponent.  And that component
                //      adds text as chips are picked/entered.
                //  TextMask is handled (and masked) by OnInputEvent()
                return;

            case FieldUIControlTypeEnum.Phone:
                if ((event.code === 'Backspace') && (caretPos > 0)) {
                    //  Manually handling this so that we can handle the mask text better (to skip the mask characters)
                    const value = targetElem.value ?? "";

                    let newCaretPos = caretPos - 1;
                    while (newCaretPos > 0) {
                        const c = value.substr(newCaretPos, 1);
                        if ((c >= '0') && (c <= '9'))
                            break;
                        newCaretPos--;
                    }

                    let newValue = value.substr(0, newCaretPos) + value.substr(caretPos);
                    newValue = this.MaskPhoneValue(newValue);

                    this.SetInputValue(targetElem, newValue, newCaretPos);

                    //  Must do this or the formControl never gets the status changed message (even though is does change to invalid)!
                    this.FormControl.CheckValid.next(true);

                    event.stopPropagation();
                    event.preventDefault();
                    return;
                }
                break;
            case FieldUIControlTypeEnum.TextSingleLine:
            case FieldUIControlTypeEnum.TextMultiLine: {
                    //  Handling the upcasing here because of lag issues at FL and in older versions of browsers.  Doing this
                    //  in OnInputEvent can cause the cursor to skip and flash (especially when typing fast or holding down a key).

                    //  https://stackoverflow.com/questions/12467240/determine-if-javascript-e-keycode-is-a-printable-non-control-character
                    //  TODO: All of these properties are deprecated and there is no replacement.  At some point, will need to change this
                    //  to do some other check using one of the other properties...
                    const keyCode = event.which || event.keyCode || 0;

                    //if ((keyCode === 13) && (this._FieldConfig.UIControlType === FieldUIControlTypeEnum.TextSingleLine)) {
                    if ((keyCode === 13) && (targetElem instanceof HTMLInputElement)) {
                        //  Eat the enter key on a single line input.  Otherwise, it triggers the first button it finds
                        //  on the page.  Which, for DigSafe and NY, is the "Release" button at the bottom of the form.
                        event.stopPropagation();
                        event.preventDefault();
                        return;
                    }

                    const isPrintable =
                        (keyCode > 47 && keyCode < 58) ||                                       // number keys
                        keyCode === 32 ||                                                       // spacebar
                        keyCode === 13 ||                                                       // enter
                        ((keyCode > 64 && keyCode < 91) && !event.ctrlKey && !event.altKey) ||  // letter keys
                        (keyCode > 95 && keyCode < 112) ||                                      // numpad keys
                        (keyCode > 185 && keyCode < 193) ||                                     // ;=,-./` (in order)
                        (keyCode > 218 && keyCode < 223);                                       // [\]' (in order)

                    if (isPrintable && targetElem && typeof (targetElem) !== "undefined") {
                        const inputLetter = (keyCode === 13) ? "\n" : event.key.toUpperCase();

                        const startString = targetElem.value ? targetElem.value.slice(0, targetElem.selectionStart) : "";
                        const endString = targetElem.value ? targetElem.value.slice(targetElem.selectionEnd, targetElem.value.length) : "";
                        let newValue = startString + inputLetter + endString;

                        const maxLength = this._FieldConfig.MaxLength;
                        if (maxLength && (newValue.length > maxLength))
                            newValue = newValue.substr(0, maxLength);

                        caretPos++;

                        if ((this._FieldConfig.UIControlType === FieldUIControlTypeEnum.TextMultiLine) && this._FieldConfig.MaxColumns) {
                            const result = TextareaInputLimiterDirective.ConformInput(newValue, caretPos, this._FieldConfig.MaxColumns, this._FieldConfig.MaxRows);
                            this.InputHint = result.InputHint;
                            if (result.Truncated) {
                                //  Conformed text was truncated because it exceeds the row limit.  Cancel the event to prevent the keystroke.
                                event.stopPropagation();
                                event.preventDefault();
                                return;
                            }
                            newValue = result.ConformedValue;
                            caretPos = result.NewCursorPosition;
                        }

                        this.SetInputValue(targetElem, newValue, caretPos);

                        //  Must do this or the formControl never gets the status changed message (even though it does change to invalid)!
                        this.FormControl.CheckValid.next(true);

                        //  This will prevent any further event handlers - including OnInputEvent().
                        //  So if we are not handling it, that will (if necessary) and arrow keys and stuff will still be handled
                        //  as they should.
                        event.stopPropagation();
                        event.preventDefault();
                        return;
                    }
                }
                break;
            case FieldUIControlTypeEnum.Email:
                if (event.code === 'Space') {
                    //  Key not allowed so ignore
                    event.stopPropagation();
                    event.preventDefault();
                }
                break;
        }
    }

    //  https://stackoverflow.com/questions/36770846/angular-2-prevent-input-and-model-changing-using-directive

    /**
     * This event is called AFTER the controls value has been changed.
     * @param targetElem - Use this so that we can put this directive on custom components
     */
    private OnInputEvent(event: InputEvent) {

        const targetElem = event.target as HTMLInputElement;

        //console.warn("OnInputEvent", event);
        if (!this._FormControl || !this._FieldConfig)
            return;     //  not configured correctly!
        if ((this._Element.nativeElement.localName === "ng-select") || (this._Element.nativeElement.localName === "mtx-select"))
            return;     //  Ignore all keyboard events on an <ng-select> and MtxSelect (which is a Material form field wrapper to ng-select)

        let valueToTransform = targetElem.value ?? "";
        let setCaretPos = false;
        let caretPos = -1;
        if (targetElem.type !== "checkbox") {
            //  Safari on Mac will throw a "type error" if we access selectionStart for a checkbox!
            setCaretPos = true
            caretPos = targetElem.selectionStart;
        }
        let checkValid = false;

        switch (this._FieldConfig.UIControlType) {
            case FieldUIControlTypeEnum.TextSingleLine:
            case FieldUIControlTypeEnum.TextMultiLine:
            case FieldUIControlTypeEnum.Email: {
                    //  OnInputEvent will not be called for Text single & multi in response to a single keystroke - that is handled by
                    //  OnKeydownEvent because it's more responsive.  But this method will still be called in response to paste or selecting
                    //  a range and deleting.

                    //  TODO: Use LowerCase: Enable the commented out code (and remove the toUpperCase below it).  Leaving emails upper in ticket entry until we get ok from One Calls
                    //  Upcase all text inputs; lowercase email
                    //if (this._FieldConfig.UIControlType === FieldUIControlTypeEnum.Email)
                    //    valueToTransform = valueToTransform.toLowerCase();
                    //else
                    //    valueToTransform = valueToTransform.toUpperCase();
                    valueToTransform = valueToTransform.toUpperCase();

                    if (event.inputType?.startsWith && event.inputType?.startsWith("insert")) {
                        //  List of all possible values: https://rawgit.com/w3c/input-events/v1/index.html#interface-InputEvent-Attributes
                        //  On *ANY* insert event, need to filter the characters to only printable/ascii characters (and newlines).
                        //  Otherwise, people tend to paste from Word or whatever which can inject unicode characters or other formatting garbage.
                        //  insertText happens like this:
                        //  1) Make sure Num Lock is set on the numeric keypad
                        //  2) Hold down an Alt key
                        //  3) Type any number on the numeric keypad (this only happens on the numeric keypad!)
                        //  4) Release the Alt key.  This immediately triggers an InputEvent (and does not fire the OnKeydown) with an unprintable character!
                        //  See here for why, it's a feature... https://www.irongeek.com/alt-numpad-ascii-key-combos-and-chart.html
                        //  Also, there is an emoji item on the right-click menu for an input - and that triggers this too!  This will strip those characters out too.
                        valueToTransform = valueToTransform.replace(/[^ -~\n]+/g, "");
                    }

                    const maxLength = this._FieldConfig.MaxLength;

                    if (maxLength && (valueToTransform.length > maxLength))
                        valueToTransform = valueToTransform.substr(0, maxLength);

                    if ((this._FieldConfig.UIControlType === FieldUIControlTypeEnum.TextMultiLine) && this._FieldConfig.MaxColumns) {
                        //  This will truncate any additional lines (if MaxRows > 0).  We have no other way to prevent the event or roll it back...
                        const result = TextareaInputLimiterDirective.ConformInput(valueToTransform, caretPos, this._FieldConfig.MaxColumns, this._FieldConfig.MaxRows);
                        valueToTransform = result.ConformedValue;
                        caretPos = result.NewCursorPosition;
                        checkValid = this.InputHint !== result.InputHint;   //  Forces TicketEntryFieldWrapper to update the InputHint
                        this.InputHint = result.InputHint;
                    }
                }
                break;

            case FieldUIControlTypeEnum.Integer: {
                    let maxIntLength = this._FieldConfig.MaxLength;
                    if (!maxIntLength || (maxIntLength < 1) || (maxIntLength > 9))
                        maxIntLength = 9;       //   anything larger will exceed limit of data type
                    valueToTransform = valueToTransform.replace(/\D/g, '').substr(0, maxIntLength);     //  strips all non-numeric characters and limit to 9 digits

                    //  Parse to int to remove stuff like repeated leading 0's...
                    const intValue = parseInt(valueToTransform);
                    if (isNaN(intValue))
                        valueToTransform = "";
                    else
                        valueToTransform = intValue.toString();
                }
                break;
            case FieldUIControlTypeEnum.Decimal: {
                    valueToTransform = valueToTransform.replace(/[^\d.]/g, '');     //  strips all non-numeric or '.'
                    const matches = valueToTransform.match(/\d+(\.\d*)?|\.\d+|\./);
                    if (matches && (matches.length > 0))
                        valueToTransform = matches[0].substr(0, 9);         //  limit to 9 digits (anything larger will exceed limit of data type)
                    else
                        valueToTransform = '';
                }
                break;

            case FieldUIControlTypeEnum.Date:
            case FieldUIControlTypeEnum.DateTime:
                //  Do nothing for dates - it's handled by the date-editor directive
                return;

            case FieldUIControlTypeEnum.Phone:
                //  The phone text mask needs to be handled manually in this directive.  We can't use the textMask directive
                //  (from angular2-text-mask) because it uses ControlValueAccessor.  And so does the autocomplete.
                //  Angular does not allow multiple ConrolValueAccessor directives on a single element.
                //  So we're manually applying the textMask (using angular-text-mask's conformToMask method - which is what it
                //  uses internally).  And then also need to handle some special keydown cases and also handle masking & un-masking the
                //  value (when we init and when we lose focus).
                setCaretPos = valueToTransform && (targetElem.selectionStart !== valueToTransform.length);
                valueToTransform = this.MaskPhoneValue(valueToTransform);
                break;
            case FieldUIControlTypeEnum.TextMask:
                //  Same as phone but the mask should be defined in FormControl.TicketFieldConfiguration.TextMask
                setCaretPos = valueToTransform && (targetElem.selectionStart !== valueToTransform.length);
                valueToTransform = this.MaskTextMaskValue(valueToTransform);
                break;
        }

        const valueSet = this.SetInputValue(targetElem, valueToTransform, setCaretPos ? caretPos : -1);

        if (checkValid) {
            //  Trigger this if we need the FormControl to check it's state or update the InputText.
            this.FormControl.CheckValid.next(true);
        }

        return valueSet;
    }

    private OnFocus(targetElem: HTMLInputElement): void {
        if (!this._FormControl || !this._FieldConfig)
            return;     //  not configured correctly!

        //  If the FormControl is registered to an Autocomplete, notify it of the focus.  That is set in AutoCompleteService.Init().
        if (this.FormControl?.Autocomplete)
            this.FormControl.Autocomplete.OnFocused();

        if ((this._FieldConfig.UIControlType === FieldUIControlTypeEnum.TextMultiLine) && this._FieldConfig.MaxColumns && this._FieldConfig.MaxRows) {
            //  For MultiLine fields that limit input, conform it to build the InputHint
            const result = TextareaInputLimiterDirective.ConformInput(targetElem.value, 0, this._FieldConfig.MaxColumns, this._FieldConfig.MaxRows);
            this.InputHint = result.InputHint;
            this.FormControl.CheckValid.next(true);
        }

        /* 8/25/2018 - per One Call request - don't do this (irth did not do this either)
        //  Fix the caret position on text input controls.  When we tab through fields, some browsers (or at least Chrome),
        //  automatically select the entire text.  So if you tab and type, you immediately wipe out the previous value!

        if (!targetElem || targetElem.localName !== "input")
            return;

        if ((this._FieldConfig.UIControlType === FieldUIControlTypeEnum.Dropdown) || (this._FieldConfig.UIControlType === FieldUIControlTypeEnum.Checkbox))
            return;

        const value = targetElem.value as string;
        if (value)
            targetElem.setSelectionRange(value.length, value.length);
        */
    }

    private OnBlur(): void {
        const value = this._FormControl.value;
        if (value && value.trim) {
            //  Trim string fields
            const trimmed = value.trim();

            //  Also always update if updateOn is blur.  Otherwise, change events don't always get sent!
            //  Only worrying about this for string fields (which is almost all of them) - other types are
            //  most likely handled by different controls and/or set to updateOn=change.
            //  The event not being triggered may be a result of handling the OnKeydownEvent() and setting the
            //  value there (instead of in OnInputEvent() ).
            if ((value !== trimmed) || (this._FormControl.updateOn === "blur"))
                this._FormControl.setValue(trimmed);
        }

        //  If the FormControl is registered to an Autocomplete, notify it of the blur.  That is set in AutoCompleteService.Init().
        if (this.FormControl?.Autocomplete)
            this.FormControl.Autocomplete.OnBlur();

        if (this.InputHint) {
            this.InputHint = "";
            this.FormControl.CheckValid.next(true);
        }
    }

    //private OnKeydownEvent($event) {
    //    console.log("TicketFieldDirectiveon.onKeydownEvent:", $event, this._Element, this._EntryFormControl);
    //}

    //public onKeydownBackspaceEvent($event) {
    //    console.log("TicketFieldDirectiveon.onKeydownBackspaceEvent:", $event, this._Element, this._EntryFormControl);
    //}

    //public onKeydownF2Event($event) {
    //    console.log("TicketFieldDirectiveon.onKeydownF2Event:", $event, this._Element, this._EntryFormControl);
    //}

    /**
     * Sets the value into the html element and form control.  Returns true if the value was set, false if
     * it was the same and nothing was done.
     * @param targetElem
     * @param value
     * @param caretPos
     */
    private SetInputValue(targetElem: HTMLInputElement, value: string, caretPos: number): boolean {
        let unmaskedValue = value;
        if (this._FieldConfig.UIControlType === FieldUIControlTypeEnum.Phone)
            unmaskedValue = this.UnmaskPhoneValue(value);
        //  TextMask values do not get unmasked like we do with phone.  We store them WITH the mask where the phone does not.

        let valueSet: boolean = false;

        //  If we changed the value, set it back into the element and the form control.
        //  The check against the current value of the _FormControl is needed when using the backspace.
        //  For some reason, that does not update the form control value so we force it to happen here so
        //  that auto complete's can get the current value when needed.
        if ((targetElem.type !== "checkbox") && ((targetElem.value !== value) || (this._FormControl.value !== value))) {
            if (this._FormControl.updateOn === "change")
                this._FormControl.setValue(unmaskedValue);
            else {
                this._FormControl.setValue(unmaskedValue, {
                    emitEvent: false,               //  This prevents firing the valueChanges event.  If we change to not do this only for onBlur, will want to remove this too.
                    emitModelToViewChange: false,   //  These 2 events prevent the html control from being updated so that we can set the unmasked value but display the masked value
                    emitViewToModelChange: false
                });
            }

            targetElem.value = value;

            //  Must get & restore the caret position or it will be set to the end of the input
            if ((caretPos >= 0) && targetElem.setSelectionRange && (targetElem.type !== "radio")) {
                targetElem.setSelectionRange(caretPos, caretPos);

                //  This doesn't seem to apply any more.  We should always be setting the cursor now or have issues
                //  when pasting text.  And the autocomplete seems to work fine now.
                //  If we have issues with that, this is what we did before 6/8/2020.
                ////  Must use setTimeout because the autocomplete trigger ALWAYS sets the caret to the end!
                ////  So this allows us to set it after it's done doing it's stuff.
                ////  But!  If we use a timeout, holding down a key (and maybe typing really fast) can cause the
                ////  cursor to skip to the end of the field!
                //if (setSetCaretPosNow)
                //    targetElem.setSelectionRange(caretPos, caretPos);
                //else
                //    setTimeout(() => targetElem.setSelectionRange(caretPos, caretPos));
            }

            valueSet = true;
        }

        //  If the FormControl is registered to an Autocomplete, notify it of the input.  That is set in AutoCompleteService.Init().
        if (this.FormControl?.Autocomplete)
            this.FormControl.Autocomplete.FetchAutoCompleteResults(unmaskedValue);

        return valueSet;
    }

    private MaskPhoneValue(value: string): string {
        if (!value || (value.length === 0) || (value === "("))
            return null;

        const len = this.UnmaskPhoneValue(value).length;
        let mask: (string | RegExp)[];
        if (len > 10)
            mask = IQ_MASK_FORMATS.BuildPhoneMask(this._FieldConfig.MaxLength);
        else if (len < 3)
            mask = IQ_MASK_FORMATS.PhoneAreaCode;
        else if (len < 6)
            mask = IQ_MASK_FORMATS.PhoneCentralOfficeCode;
        else if (this.FormControl.disabled) {
            //  If disabled, use the "AllowEmpty" mask.  Needed for in/ky because they allow "unknown" for the contact phone
            //  which needs to be handled as "000-000-0000" which is not a valid phone.
            mask = IQ_MASK_FORMATS.PhoneLineNumberAllowEmpty;
        }
        else
            mask = IQ_MASK_FORMATS.PhoneLineNumber;

        return conformToMask(value, mask, { guide: false }).conformedValue;
    }

    private UnmaskPhoneValue(value: string): string {
        if (!value)
            return null;
        return value.replace(/\D/g, '')
    }

    private MaskTextMaskValue(value: string): string {
        if (!value)
            return value;

        //  This should be an object that contains "mask" and "guide" properties.
        const textMaskOptions = this.FormControl.FieldConfiguration.TextMask;
        if (!textMaskOptions || !textMaskOptions.mask)
            return value;

        //  textMaskOptions.mask can be either a function (that returns an array) or an array.  See https://github.com/text-mask/text-mask
        //  conformToMask is supposed to handle a function, but it doesn't!
        const mask = (typeof textMaskOptions.mask === "function") ? textMaskOptions.mask(value) : textMaskOptions.mask;

        const conformed = conformToMask(value, mask, { guide: false });
        return conformed.conformedValue;
    }
}
