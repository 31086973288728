import { formatDate } from '@angular/common';
import { AfterContentInit, AfterViewInit, Component, ContentChild, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { FieldUIControlTypeEnum } from 'Enums/FieldUIControlType.enum';
import { BehaviorSubject, merge, Subject } from "rxjs";
import { debounceTime, takeUntil } from 'rxjs/operators';
import { EntryFieldDirective } from "../Directives/EntryField.directive";

//  Note: To show a custom error message, do something like this.  These errors are displayed immediately.
//      formControl.setErrors({ message: "show this error message" });

//  Wraps content that contains a "matInput" directive to add error handling, hint text, command links, and floating placeholder/label.
//  Currently, MatFormField and MatInput do not support content projection correctly.
//  See these links:
//      https://github.com/angular/components/issues/9411
//      https://github.com/angular/angular/issues/37319
//      https://stackoverflow.com/questions/63898533/angular-ng-content-not-working-with-mat-form-field
//      https://stackoverflow.com/questions/46705101/mat-form-field-must-contain-a-matformfieldcontrol
//  So the MatFormField will not see the MatInput inside the "ng-content".  And the MatInput will not get the reference to the
//  MatFormField when it is constructed (which happens via DI in it's constructor).  Also, the MatInput adds css classes to itself
//  only if it finds the MatFormField - again, in it's constructor.
//  The code that shows this is here: https://github.com/angular/components/blob/main/src/material/input/input.ts
//  To work around these issues, we have to manually set the control in to our MatFormField and then add the missing css classes.

//  Need to always add the <mat-error> tag and then hide it when we should not be showing errors.  Otherwise, if we put the *ngIf on the
//  <mat-error>, we get an "expression changed" error from the MtxSelect component when the error is displayed.
@Component({
    selector: 'iq-entry-form-field',
    template: `
<mat-form-field subscriptSizing="dynamic" [ngClass]="{'no-floating-placeholder': NoFloatingPlaceholder }">
    <mat-label *ngIf="Placeholder">{{Placeholder}}</mat-label>
    <div class="iq-entry-form-field-content">
        <ng-content></ng-content>
    </div>
    <mat-error [ngClass]="{'hide': !NotValid || !((ShowValidationErrors | async) || ForceErrorDisplay)}">{{ErrorText}}</mat-error>
    <mat-hint *ngIf="WarningMessage" align="start" style="color:red">{{WarningMessage}}</mat-hint>
    <mat-hint *ngIf="HintText || CommandTextList" align="end" style="display:flex">
        <div *ngIf="HintText" style="flex-grow:1">{{HintText}}</div>
        <div *ngIf="CommandTextList" style="margin-left:auto; display:flex">
            <div *ngFor="let cmd of CommandTextList; index as i">
                <span *ngIf="i>0" style="padding-right:10px">, </span>
                <span [ngClass]="{'link': HaveCommandClickHandler, 'accent-color': !HaveCommandClickHandler}" (click)="OnCommandClicked(i, $event)"><strong>{{cmd}}</strong></span>
            </div>
        </div>
    </mat-hint>
</mat-form-field>`
})
export class EntryFormFieldComponent implements OnInit, AfterContentInit, AfterViewInit, OnDestroy {

    @ContentChild(EntryFieldDirective)
    private _EntryField: EntryFieldDirective;

    //  TODO: Initially naming it this to avoid conflicts with all of the other controls that need to have placeholder moved to a mat-label
    @Input("placeholderLabel")
    public Placeholder: string;

    //  Set to true to remove all of the top padding that is reserved for the floating placeholder.
    //  Defaults to false since most forms are already using this padding to separate the form fields.
    @Input("NoFloatingPlaceholder")
    public NoFloatingPlaceholder: boolean;

    //  Hint text shown below the input control using a <mat-hint>.
    //  Has to be specified here (and not included in the projected content) or the mat-hint control will not be wired up
    //  the MatFormField correctly and the text ends up being displayed as a suffix.
    @Input()
    public HintText: string = null;

    //  Commands or text shown right-aligned below the input control.
    //  This can either be a string (for a single Hint) or an array of strings for multiple.
    //  If a CommandClickHandler is supplied, these will be styled as a link.
    public CommandTextList: string[] = null;
    @Input() set CommandText(command: string | string[]) {
        if (!command)
            this.CommandTextList = null;
        else if (typeof command === "string")
            this.CommandTextList = [command];
        else
            this.CommandTextList = command;
    }

    public HaveCommandClickHandler: boolean = false;

    @Output() CommandClicked: EventEmitter<number> = new EventEmitter<number>();

    public ShowValidationErrors: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public NotValid: boolean = false;
    public ErrorText: string = "";
    public WarningMessage: string;
    public ForceErrorDisplay: boolean = false;

    @ViewChild(MatFormField, { static: true })
    private _MatFormField: MatFormField;

    @ContentChild(MatFormFieldControl, { static: true })
    private _ProjectedFormFieldControl: MatFormFieldControl<unknown>;

    private _Destroyed: Subject<void> = new Subject();

    constructor() { }

    ngOnDestroy() {
        this._Destroyed.next();
        this._Destroyed.complete();

        this._EntryField = null;
        this.ShowValidationErrors = null;       //  We get this instance from the FormGroup so make sure to release it
    }

    public ngOnInit(): void {
        //  See notes above.  This sets the projected MatInput in to our MatFormField.
        //  We are doing this in the OnInit handler to avoid issues with the MatFormField throwing an error saying: mat-form-field must contain a MatFormFieldControl
        //  That happens some time after OnInit.  This requires us to use "static: true" on the @ViewChild and @ContentChild properties.
        //  If the projected content contains an *ngIf, the control may not be found here and will be null.
        //  If that needs to be supported, the only option will be to remove "static: true" and then handle this in ngAfterViewInit.
        //  And that means our MatFormField will throw an error.  The only way to avoid that is to add this to the html template:
        //      <input *ngIf="!Initialized" hidden matInput />
        //  And set Initialized=true in ngAfterViewInit after we swap out the control.  See: https://stackoverflow.com/questions/63898533/angular-ng-content-not-working-with-mat-form-field
        if (!this._ProjectedFormFieldControl) {
            console.error("ERROR: Content projected in to EntryFormFieldComponent does not contain a control with the 'matInput' directive");
            throw new Error("ERROR: Content projected in to EntryFormFieldComponent does not contain a control with the 'matInput' directive");
        } else
            this._MatFormField._control = this._ProjectedFormFieldControl;
    }

    public ngAfterViewInit(): void {
        //  Add the css classes to the MatInput that would have been added had it found our MatFormField.
        //  Must be done in ngAfterViewInit or the changes get overwritten (probably by the timing of when MatInput applies the css classes to itself).
        if (this._ProjectedFormFieldControl instanceof MatInput) {
            const matInput = this._ProjectedFormFieldControl as MatInput;
            const elementRef = (matInput as any)?._elementRef as ElementRef<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>;        //  protected member of MatInput
            if (elementRef) {
                //  These come from the "host" section here: https://github.com/angular/components/blob/main/src/material/input/input.ts
                //  They are all the css classes that would have been added if _isInFormField had been true during construction (which is set if _formField is set in the constructor)
                if (matInput._isTextarea)
                    elementRef.nativeElement.classList.add("mat-mdc-form-field-textarea-control");
                elementRef.nativeElement.classList.add("mat-mdc-form-field-input-control");
                elementRef.nativeElement.classList.add("mdc-text-field__input");
            }
        }
    }

    //  Must use ngAfterContentInit because our EntryFormControl is not set until after the view has initialized.
    public ngAfterContentInit(): void {
        this.CheckIfInvalid();

        if (this._EntryField) {
            merge(this._EntryField.FormControl.statusChanges,
                this._EntryField.FormControl.CheckValid)
                .pipe(takeUntil(this._Destroyed), debounceTime(10))
                .subscribe(() => {
                    this.CheckIfInvalid();
                    this.CheckInputHint();
                });

            const showValidationErrors = this._EntryField.FormControl.EntryFormGroup.ShowValidationErrors;
            if (showValidationErrors)
                this.ShowValidationErrors = showValidationErrors;
            else {
                //  If ShowValidationErrors not found, it means the FormGroup used is not our EntryFormGroupBase.
                //  (which is done on some Dialogs).  Make those show validations errors when the FormControl
                //  is touched to match the way Angular does this by default.
                this._EntryField.FormControl.statusChanges
                    .pipe(takeUntil(this._Destroyed))
                    .subscribe(() => {
                        if (this._EntryField?.FormControl && this._EntryField.FormControl.touched)
                            this.ShowValidationErrors.next(true);
                    });
            }
        }

        this.ShowValidationErrors
            .pipe(takeUntil(this._Destroyed))
            .subscribe(show => {
                this.CheckIfInvalid();  //  Added this on 4/25/2020 - see comments in EntryFormGroupBase.DisableControl() for why if this causes problems
            });

        this.HaveCommandClickHandler = this.CommandClicked.observed;
    }

    private CheckIfInvalid(): void {
        const formControl = this._EntryField?.FormControl;
        if (!formControl)
            return;     //  Being destroyed

        const validationWarning = formControl.GetValidationWarning();
        const notValid = !formControl.valid && !formControl.disabled;

        this.WarningMessage = null;

        //  Can skip everything below if the control is currently valid and it was valid before too.
        //  If it's not valid, we need to re-evaluate because we may have multiple validation errors and
        //  one of them may have changed.
        if (!notValid && (this.NotValid === notValid)) {
            if (validationWarning)
                this.WarningMessage = validationWarning.Message;
            return;
        }

        this.NotValid = notValid;

        let forceErrorDisplay: boolean = false;

        if (notValid) {
            let errorMessage = "Unhandled validation error in EntryFieldWrapperComponent";

            if (formControl.errors) {
                if (formControl.errors.message) {
                    //  These are errors that we set ourselves.  i.e. "Cross street is required for an Intersection"
                    //  Need to force these to display immediately.
                    //  And if the formControl has not been touched, also set the message in to the WarningMessage.
                    //  Necessary because errors are only shown if the control is invalid and either has been touched or
                    //  the form has been submitted.  So to make sure the error is shown before that happens, we show it
                    //  as the WarningMessage (which is also red - the entire background is just not colored red since not touched).
                    //  If we wanted the error (and red background to show), we could do formControl.markTouched() to make that happen.
                    errorMessage = formControl.errors.message;
                    forceErrorDisplay = true;
                    if (!formControl.touched)
                        this.WarningMessage = formControl.errors.message;
                }
                else if (formControl.errors.required)
                    errorMessage = "This field is required";
                else if (formControl.errors.pattern) {
                    const fieldConfig = formControl.FieldConfiguration;
                    if (fieldConfig && (fieldConfig.UIControlType === FieldUIControlTypeEnum.Email))
                        errorMessage = "Invalid email address";
                    else if (fieldConfig && (fieldConfig.UIControlType === FieldUIControlTypeEnum.Phone))
                        errorMessage = "Invalid phone number";
                    else if (fieldConfig && (fieldConfig.UIControlType === FieldUIControlTypeEnum.TextMask))
                        errorMessage = formControl.errors.pattern;
                    else {
                        errorMessage = "Input does not match required pattern";
                        console.error("pattern validation error", formControl.errors, formControl);
                    }
                } else if (formControl.errors.owlDateTimeMax && formControl.errors.owlDateTimeMax.max) {
                    //  Error from the owl date/time component used by EntryFieldDateComponent.
                    errorMessage = "Date must be on or before " + formatDate(formControl.errors.owlDateTimeMax.max, "MM/dd/yyyy, hh:mm a", "en-US");
                } else if (formControl.errors.owlDateTimeMin && formControl.errors.owlDateTimeMin.min) {
                    //  Error from the owl date/time component used by EntryFieldDateComponent.
                    errorMessage = "Date must be on or after " + formatDate(formControl.errors.owlDateTimeMin.min, "MM/dd/yyyy, hh:mm a", "en-US");
                }
                else
                    console.error("Unhandled validation error", formControl.errors);
            }

            this.ErrorText = errorMessage;

            this.ForceErrorDisplay = forceErrorDisplay;
        }
    }

    //  Check to see if the _EntryField has an InputHint that needs to be set in to the HintTextList.
    //  This method is called when the FormControls CheckValid is triggered - which the EntryFieldDirective
    //  does on input changes.
    private CheckInputHint(): void {
        if ((this._EntryField?.InputHint === null) || (this._EntryField?.InputHint === undefined))
            return;

        //  No controls (currently!) set HintTextList and also cause the EntryFieldDirective InputHint
        //  to be set so it's safe to replace/set this.  If that changes, will need to figure out how to handle...
        //  Could probably just store the InputHint directly and set it in the html template in addition
        //  to whatever is in HintTextList.
        if (!this.CommandTextList || this.CommandTextList.length !== 1)
            this.CommandTextList = [this._EntryField.InputHint];
        else if (this.CommandTextList[0] !== this._EntryField.InputHint)
            this.CommandTextList[0] = this._EntryField.InputHint;
        else
            return;     //  No change so do nothing
    }

    private OnCommandClicked(hintNumber: number, $event: MouseEvent): void {
        this.CommandClicked.next(hintNumber);

        //  Must stop propagation or the input control will gain focus (which may then bring up the
        //  autocomplete results if the field has one).
        $event.stopPropagation();
    }
}
