import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatInput } from '@angular/material/input';
import { map, Observable, startWith, take } from 'rxjs';
import { ResponseConfig } from '../../../../Models/Configuration/Response.model';
import { CommonService } from '../../../../Services/CommonService';
import { EnumService } from '../../../../Services/Enum.service';
import { EntityHasIDValidator } from '../../../../Shared/Components/Forms/Validation/Validators/EntityHasID.validator';
import { DynamicContentDirective } from '../../../../Shared/Directives/DynamicContent/DynamicContent.directive';
import { ICustomResponseDataComponent } from '../Custom/ICustomResponseDataComponent';
import { AddPositiveResponseData } from './AddPositiveResponse.component';
import { ComponentLookupRegistry } from 'Services/ComponentLookup.service';


@Component({
    selector: 'iq-positive-response-common',
    templateUrl: './PositiveResponseCommon.component.html',
    styleUrls: ['./PositiveResponseCommon.component.scss'],
    providers: [CommonService]
})
export class PositiveResponseCommonComponent implements OnInit, OnDestroy {
    @ViewChild("commentInput", { read: MatInput, static: true })
    public CommentInput: MatInput;

    @ViewChild("responseInput", { read: MatAutocompleteTrigger, static: true })
    public ResponseAutocompleteTrigger: MatAutocompleteTrigger;

    @ViewChild("responseInput", { read: MatInput, static: true })
    public ResponseInput: MatInput;

    @ViewChild(DynamicContentDirective, { static: true })
    private _CustomResponseContent: DynamicContentDirective;

    @Input()
    public FormGroup: UntypedFormGroup;

    @Input()
    public Data: AddPositiveResponseData;

    @Input()
    public AffectedTicketNumbers: string[];

    @Input()
    public UnaffectedTicketNumbers: string[];

    public CommentFormControl: UntypedFormControl;
    public RespondantFormControl: UntypedFormControl;
    public ResponseFormControl: UntypedFormControl;
    public IsLocalUser: boolean;


    public FilteredResponses: Observable<ResponseConfig[]>;

    private _AvailableResponses: ResponseConfig[] = null;
    private _CustomResponseCode: string;
    private _CustomResponseComponent: ICustomResponseDataComponent;

    constructor(private _CommonService: CommonService, private _EnumService: EnumService) {
        this._CommonService.AuthenticationService.CurrentUserObserver().pipe(take(1)).subscribe(user => this.IsLocalUser = user.IsLocalUser);
    }

    public ngOnDestroy(): void {
        //  Nulling this because there is some kind of timing issue if the dialog is shown, dismissed, and then something in a timeout or in response
        //  to a delayed api call then calls ShowPanel()
        this.ResponseAutocompleteTrigger = null;
    }

    public get CustomResponseData(): Object {
        return this._CustomResponseComponent?.GetData();
    }

    public get CanSave(): boolean {
        return (!this._CustomResponseComponent || this._CustomResponseComponent.IsValid());
    }
    //  Used to force the panel to display when clicked.  Otherwise, if the input already has focus, it's not shown automatically!
    public ShowPanel(): void {
        if (this.ResponseAutocompleteTrigger)
            this.ResponseAutocompleteTrigger.openPanel();
    }

    public ResponseDisplayFn(response: ResponseConfig): string | undefined {
        return response?.Code;
    }

    public ngOnInit(): void {
        this.CommentFormControl = this.FormGroup.get("Comment") as UntypedFormControl;
        this.RespondantFormControl = this.FormGroup.get("Respondant") as UntypedFormControl;
        this.ResponseFormControl = this.FormGroup.get("Response") as UntypedFormControl;

        this._EnumService.Responses.pipe(take(1)).subscribe(items => {
            //  Filter the responses if necessary.  TicketTypeID should *ALWAYS* be set here!
            //  Response is usable if TicketTypeIDs not set/empty or contains our TicketTypeID.
            this._AvailableResponses = items
                .filter(r => !this.Data.TicketTypeID || !r.TicketTypeIDs || (r.TicketTypeIDs.length === 0) || r.TicketTypeIDs.some(id => id === this.Data.TicketTypeID));

            if (this.ResponseFormControl)
                this.ResponseFormControl.setValue(null);        //  To trigger the inital build of values or nothing will display the first time
        });

        this.FilteredResponses = this.ResponseFormControl.valueChanges.pipe(startWith(null), map(val => {
            if (!val || val === "")
                return this._AvailableResponses ?? [];

            if (val.ID) {
                //  When we have a response picked, return all responses.  This makes it easier if you pick one and
                //  then need to change it - you get a list of all right away.  Which makes it work better on the phone.
                return this._AvailableResponses;
                //return [val];
            }

            return this._AvailableResponses ? this._AvailableResponses.filter(r => r.Code.startsWith(val.iqToUppercase())) : [];
        }));

        this.ResponseFormControl.valueChanges.subscribe(response => this.OnResponsePicked(response));
    }

    private OnResponsePicked(response: ResponseConfig): void {
        if (!response?.ID)
            return;     //  String value - user is typing so ignore this

        if (response.RequireComments)
            this.CommentFormControl.setValidators([Validators.required]);
        else
            this.CommentFormControl.clearValidators();
        this.CommentFormControl.updateValueAndValidity();

        if (response.Code === this._CustomResponseCode)
            return;     //  Already set

        //  Check for a custom component that should be created and injected into the form
        const componentKey = this._CommonService.SettingsService.CurrentOneCallCenterCode + "-Response-Code-" + response.Code;
        const customComponentClassRef = ComponentLookupRegistry.get(componentKey);
        if (customComponentClassRef) {
            this._CustomResponseComponent = this._CustomResponseContent.LoadComponent<ICustomResponseDataComponent>(customComponentClassRef);
            this._CustomResponseCode = response.Code;
            return;
        }

        if (this._CustomResponseComponent) {
            this._CustomResponseContent.ClearComponent();
            this._CustomResponseCode = null;
            this._CustomResponseComponent = null;
            this.CommentInput.focus();
        }
    }
}
