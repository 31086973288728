<h2 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    Update Email
</h2>
<mat-dialog-content class="mat-typography" [formGroup]="group" style="overflow: auto; display: flex; flex-direction: column;">
    <busy-overlay [show]="isBusy"></busy-overlay>
    <p style="flex: 1 1 0%;" class="mat-body-2">
        You are about to update the email address for the username <b>{{Username}}</b>.  Once changed, the new email will
        receive an email with a code that the user will need to enter into the system to verify they own/have access to the email.
        The user will not be able to do a password reset until they have a verified email.
    </p>

    <mat-form-field style="flex: 1 1 0%;">
        <mat-label>New Email</mat-label>
        <input matInput type="search" autocomplete="off" formControlName="NewEmail"
               (keyup.enter)="onChangeEmail()" autofocus />
        <mat-error *ngIf="newEmail.hasError('required')">required</mat-error>
        <mat-error *ngIf="newEmail.hasError('pattern')">format not valid</mat-error>
    </mat-form-field>

    <mat-form-field style="flex: 1 1 0%;">
        <mat-label>Confirm Email</mat-label>
        <input matInput type="search" autocomplete="off" blockPaste
               formControlName="ConfirmEmail" (keyup.enter)="onChangeEmail()" />
        <mat-error *ngIf="confirmEmail.hasError('required')">required</mat-error>
        <!--we don't care about the pattern on the confirming email, because the other one has it-->
        <mat-error *ngIf="confirmEmail.hasError('equalValue')">emails must match</mat-error>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="null">Cancel</button>
    <button mat-button (click)="onChangeEmail()" color="primary" [disabled]="!group.valid || isBusy">
        Confirm
    </button>
</mat-dialog-actions>
